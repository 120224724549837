<template>
  <v-container fluid>
    <base-v-component
      heading="Update Prescription"
    />
    <base-material-card
      icon="mdi-clipboard-text"
      title="Update User"
      class="px-5 py-3"
    >
      <v-form
        ref="form"
        @submit.prevent="submit"
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="prescription.delivery_status_id"
                :label="$t('delivery_status')"
                :error="!!validationErrors.delivery_status_id"
                :error-messages="validationErrors.delivery_status_id"
                :items="deliveryStatus"
                clearable
                item-text="name"
                item-value="id"
                outlined
                :loading="loading"
                @input="validationErrors.delivery_status_id = ''"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="prescription.rejection_reason"
                :label="$t('rejection_reason')"
                :error="!!validationErrors.rejection_reason"
                :error-messages="validationErrors.rejection_reason"
                :items="rejectionReasons"
                item-value="id"
                outlined
                clearable
                :loading="loading"
                @input="validationErrors.rejection_reason = ''"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="prescription.approval_number"
                :label="$t('approval_number')"
                :error="!!validationErrors.approval_number"
                :error-messages="validationErrors.approval_number"
                type="number"
                outlined
                clearable
                :loading="loading"
                @input="validationErrors.approval_number = ''"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="prescription.delivery_date"
                :label="$t('delivery_date')"
                :error="!!validationErrors.delivery_date"
                :error-messages="validationErrors.delivery_date"
                type="date"
                outlined
                clearable
                :loading="loading"
                @input="validationErrors.delivery_date = ''"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="prescription.partner_note"
                :label="$t('partner_note')"
                :error="!!validationErrors.partner_note"
                :error-messages="validationErrors.partner_note"
                outlined
                clearable
                :loading="loading"
                @input="validationErrors.partner_note = ''"
              />
            </v-col>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="success"
                class="mr-0"
                type="submit"
              >
                Submit Form
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>
<script>

  export default {
    name: 'UpdatePrescription',
    data () {
      return {
        rejectionReasons: [],
        deliveryStatus: [],
        prescription: {
          delivery_status: null,
          rejection_reason: null,
          approval_number: null,
          partner_note: null,
          delivery_date: null,
        },
        validationErrors: {},
        loading: false,
      }
    },
    async mounted () {
      try {
        this.loading = true
        const [{ data: prescription }, { data: reason }, { data: deliveryStatus }] = await Promise.all([
          this.$authApi.get(`/prescriptions/${this.$route.params.id}`),
          this.$authApi.get(`/reasons`),
          this.$authApi.get(`/delivery-statuses`)
        ])

        this.prescription = prescription
        this.rejectionReasons = reason
        this.deliveryStatus = deliveryStatus
      } catch (e) {
        if (e.response.status === 404) {
          await this.$router.push({ name: 'prescriptions.list' })
        }
      } finally {
        this.loading = false
      }
    },
    methods: {
      async submit () {
        try {
          await this.$authApi.put(`/prescriptions/${this.$route.params.id}`, this.prescription)

          await this.$router.push({ name: 'prescriptions.show' })
        } catch (e) {
          console.log(e.response.status);
          if (e.response.status === 422) {
            this.validationErrors = e.response.data.errors
          }
        }
      }
    }
  }
</script>
